<template>
  <div class="userinfo-page">
    <div class="left-menu">
      <div class="top-info flex-column-center">
        <div
          class="user-header large"
          @click="changeAvatar"
          :class="`header-${header}`"
        >
          <div class="label">{{ $t('xiu-gai-tou-xiang') }}</div>
        </div>
        <div class="email">{{ username }}</div>
        <div class="id">
          ID：{{ userId }}
          <i class="el-icon-copy-document copy-icon" @click="copyData"></i>
        </div>
      </div>
      <div class="menu-list">
        <div
          class="menu flex-center-start"
          v-for="(item, i) in menuList"
          :key="i"
          @click="toPath(item)"
          :class="activeRoute === item.path ? 'active' : ''"
        >
          <i :class="item.icon"></i>
          <span class="name">{{ item.name }}</span>
        </div>
      </div>

      <div class="flex-column-center bottom">
        <div class="qrcode" ref="qrcode"></div>
        <div>{{ $t('sao-ma-er-wei-ma-xia-zai-app') }}</div>
      </div>
    </div>
    <div class="right-main">
      <router-view></router-view>
    </div>

    <el-dialog
      title="$t('tou-xiang-xuan-ze')"
      :visible.sync="headerDialog"
      width="460px"
      class="header-dialog"
    >
      <div class="header-list">
        <div
          class="user-header big"
          :class="`header-${i}`"
          v-for="i in 20"
          :key="i"
          @click="changeHeader(i)"
        >
          <i class="el-icon-success" v-if="avatar == i"></i>
        </div>
      </div>

      <el-button type="primary" class="submit-btn" @click="submitAvatar">{{
        $t('que-ding')
      }}</el-button>
    </el-dialog>
  </div>
</template>
<script>
import { getUserId } from '@/utils/auth'
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      qrcodeText: '123123',
      activeRoute: '',
      headerDialog: false,
      avatar: 1
    }
  },
  computed: {
    menuList() {
      return [
        {
          icon: 'iconfont icon-yibiaopan',
          name: this.$t('yi-biao-pan'),
          path: 'userInfoDashboard'
        },
        {
          icon: 'iconfont icon-qianbao',
          name: this.$t('wo-de-qian-bao'),
          path: 'userInfoWallet'
        },
        {
          icon: 'iconfont icon-xiugaidingdan',
          name: this.$t('wo-de-ding-dan'),
          path: 'userInfoOrderList'
        },
        {
          icon: 'iconfont icon-shangpinshoucang',
          name: this.$t('shang-pin-shou-cang-0'),
          path: 'userInfoCollect'
        },
        {
          icon: 'iconfont icon-guanzhudianpu',
          name: this.$t('guan-zhu-dian-pu-0'),
          path: 'userInfoFollowShop'
        },
        {
          icon: 'el-icon-message',
          name: this.$t('xi-tong-xiao-xi'),
          path: 'userInfoNotice'
        },
        {
          icon: 'iconfont icon-setup',
          name: this.$t('she-zhi'),
          path: 'userInfoSetting'
        }
      ]
    },
    userId() {
      return getUserId()
    },
    username() {
      return this.$store.state.username
    },
    header() {
      return this.$store.state.avatar
    }
  },
  watch: {
    $route() {
      this.activeRoute = this.$route.name
    }
  },
  mounted() {
    this.init()
    this.activeRoute = this.$route.name
    this.avatar = this.header
  },
  methods: {
    init() {
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: this.qrcodeText,
        width: 132,
        height: 132,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    copyData() {
      let data = this.userId
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$message.success(this.$t('fu-zhi-cheng-gong'))
    },
    toPath(data) {
      this.$router.push({
        name: data.path
      })
    },
    changeHeader(i) {
      this.avatar = i
    },
    changeAvatar() {
      this.headerDialog = true
    },
    submitAvatar() {
      this.$store.state.avatar = this.avatar
      this.headerDialog = false
    }
  }
}
</script>